.branding {


    &__header {
        text-align: center;
    }

}

.app {
    width: span(12 of 12);
    /*margin-left: span(4 of 12);*/
    /*margin-right: span(4 of 12);*/
}

.search {

}
