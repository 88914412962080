*, *:before, *:after {
    box-sizing: border-box;
}


html {
  font-size: 100%;
  line-height: 1;
  text-size-adjust: 100%;
}

body {
  font-family: $primaryfont;
  background-color: $white;
}

a {
  text-decoration: none;
  color: $linkcolor;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: $fontsizebase;
  line-height: $bodylineheight;
  color: $verydark;
}

h1 {
  font-family: $primaryfont;
  font-weight: 600;
  color: $verydark;
}

ul, ol {
  /*list-style: none;*/
  margin-top: 0;
}

img {
  max-width: 100%;
  height: auto;
}
 
input {
  vertical-align: middle;
}

input {
  padding: .5em;
  margin-bottom: 1em;
  width: 100%;
  font-size: 1em;
  /*display: inline-block;*/
  border-left-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: $gray;
  color: $fontcolor;

  &[type='text']:focus {
    @extend textarea:focus;
  }

  &[type='email']:focus {
    @extend textarea:focus;
  }

} // input

figure {
  margin: 0;
}
